import { about, consulting, contentBlocks, intro, team } from "../../content";

import { lazy } from "react";

const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Team = lazy(() => import("../../components/Team/Team"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        type="right"
        first="true"
        title={intro.title}
        content={intro.text}
        button={intro.button}
        icon={intro.image}
        id={intro.id}
        titleSize={intro.titleSize}
      />
      <MiddleBlock
        title={about.title}
        content={about.text}
        button={about.button}
        titleSize={about.titleSize}
        id={about.id}
      />
      <ContentBlock
        type="left"
        title={consulting.title}
        content={consulting.text}
        section={consulting.section}
        icon={consulting.icon}
        size={consulting.size || "220px"}
        id={consulting.id}
      />
      {contentBlocks.map((content, i) => {
        return (
          <ContentBlock
            type={i % 2 ? "left" : "right"}
            title={content.title}
            content={content.text}
            icon={content.icon}
            id={content.id}
            size={content.size || "220px"}
          />
        );
      })}
      <Team title={team.title} id={team.id} profiles={team.profiles} />
      {/* <ContactForm title={contact.title} content={contact.text} id="contact" /> */}
    </Container>
  );
};

export default Home;
